import { default as _91slug_93zBdR244lmMMeta } from "/app/app/pages/d/[slug].vue?macro=true";
import { default as _91visualisationId_93y5RdOMaL8DMeta } from "/app/app/pages/dashboards/[id]/[visualisationId].vue?macro=true";
import { default as configure0JCyIQhS4MMeta } from "/app/app/pages/dashboards/[id]/configure.vue?macro=true";
import { default as index2eVPA3DXeMMeta } from "/app/app/pages/dashboards/[id]/index.vue?macro=true";
import { default as createsJkBITuDniMeta } from "/app/app/pages/dashboards/create.vue?macro=true";
import { default as indexerMxgpkeVBMeta } from "/app/app/pages/dashboards/index.vue?macro=true";
import { default as dashboardsibWAUjLfboMeta } from "/app/app/pages/dashboards.vue?macro=true";
import { default as indexM6mJRhIbwTMeta } from "/app/app/pages/index.vue?macro=true";
import { default as profile4G4rdWHhIYMeta } from "/app/app/pages/profile.vue?macro=true";
export default [
  {
    name: "d-slug",
    path: "/d/:slug()",
    meta: _91slug_93zBdR244lmMMeta || {},
    component: () => import("/app/app/pages/d/[slug].vue").then(m => m.default || m)
  },
  {
    name: dashboardsibWAUjLfboMeta?.name,
    path: "/dashboards",
    component: () => import("/app/app/pages/dashboards.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboards-id-visualisationId",
    path: ":id()/:visualisationId()",
    component: () => import("/app/app/pages/dashboards/[id]/[visualisationId].vue").then(m => m.default || m)
  },
  {
    name: "dashboards-id-configure",
    path: ":id()/configure",
    component: () => import("/app/app/pages/dashboards/[id]/configure.vue").then(m => m.default || m)
  },
  {
    name: "dashboards-id",
    path: ":id()",
    component: () => import("/app/app/pages/dashboards/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboards-create",
    path: "create",
    component: () => import("/app/app/pages/dashboards/create.vue").then(m => m.default || m)
  },
  {
    name: "dashboards",
    path: "",
    component: () => import("/app/app/pages/dashboards/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexM6mJRhIbwTMeta || {},
    component: () => import("/app/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/app/app/pages/profile.vue").then(m => m.default || m)
  }
]
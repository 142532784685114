export default {
    root: ({ context }) => ({
        class: [
            'flex items-center justify-between bg-surface-0 dark:bg-surface-900 text-surface-600 dark:text-surface-0/70 py-[1.125rem] px-[0.5rem] outline-transparent',
            {
                'focus-visible:outline-offset-2 focus-visible:ring-1 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400':
                    !context.disabled,
            },
            { 'font-medium': context.active },
        ],
    }),
    toggleIcon: 'inline-block text-surface-900 dark:text-surface-0 w-3 h-3',
};

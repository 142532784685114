<script lang="ts" setup>
// TODO: Move site name to appConfig
const siteName = 'Product';
useHead({
    titleTemplate: (title) => {
        return title ? `${title} - ${siteName}` : siteName;
    },
});
</script>

<template>
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
</template>

import * as Sentry from '@sentry/vue';

async function lazyLoadSentryIntegrations() {
    const { replayIntegration } = await import('@sentry/vue');
    Sentry.addIntegration(
        replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    );
}

export default defineNuxtPlugin({
    name: 'sentry',
    parallel: true,
    async setup(nuxtApp) {
        const router = useRouter();
        const {
            public: { sentry, appUrl },
        } = useRuntimeConfig();

        if (!sentry.dsn) {
            return;
        }

        // Initialise Sentry
        Sentry.init({
            app: nuxtApp.vueApp,
            dsn: sentry.dsn,
            environment: sentry.environment,
            integrations: [
                Sentry.browserTracingIntegration({ router }),
                // Sentry.replayIntegration({
                //     maskAllText: false,
                //     blockAllMedia: false,
                // }),
            ],

            tracesSampleRate: 0.2, // TODO: Configure this whole part as you need it!

            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [appUrl],

            // Session Replay
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });

        // Lazy load integrations, to reduce bundle
        lazyLoadSentryIntegrations();
    },
});

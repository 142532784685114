export default {
    root: 'flex overflow-hidden w-full flex-row-reverse',
    controls: {
        class: ['flex flex-col justify-center gap-2', 'pl-[1.125rem] pr-[0.1rem]'],
    },
    container: {
        class: [
            'flex-auto',
            'rounded-md',
            'bg-surface-0 dark:bg-surface-900',
            'border border-surface-200 dark:border-surface-700',
            'outline-none',
        ],
    },
};
